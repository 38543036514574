import { Button, Col, Row } from "react-bootstrap";
import { Html } from "../../../Components/Html";
import { VerContry, VerEstado } from "../../../Components/Contry/Ver";
import { Component } from "react";
interface Prop {
    html: any,
    suscrito: boolean
    user: any
    id: number
}
export default class Participante extends Component<Prop> {
    render = () => {
        const { props: { html, suscrito, user: { nombre, estado, pais } }, send } = this;
        return suscrito ? <Row className="boton mr-3 ml-1 p-2"><Button block><b>Gracias por suscribirte</b></Button></Row> : <Col xs={12} className="m-2 p-2">
            <Row className="w-100 h-100 d-flex align-items-center">
                <Col xs={12} className='m-2 p-0'><Html html={html} /></Col>
                <Col className='m-2 p-0' xs={12}>NOMBRE: {nombre}</Col>
                <Col className='m-2 p-0' xs={12}>TELEFONO: ****-***-****</Col>
                <Col className='m-2 p-0' xs={12}>CORREO ELECTRONICO: ****@*****.***</Col>
                <Col className='m-2 p-0' xs={12}><VerContry pais={pais} /></Col>
                <Col className='m-2 p-0' xs={12}><VerEstado pais={pais} estado={estado} /></Col>
                <Col xs={12} className='m-2 p-0'>
                    <Button block variant="primary" className='mb-2' onClick={send}>Suscribirse</Button>
                </Col>
            </Row>
        </Col>
    }
    send = () => { }
}
