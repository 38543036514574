import { Html } from "../../Components/Html";
import { Icon2 } from "../../Components/Icons";
export function Itens({ ori, config: { card, border1, border2, back: iback, orientation, font }, iten, i }: any) {
    if (iten === undefined) return <></>;
    const { html, props: { icon, color, back, aux, fill } } = iten;
    const style = {
        border: 'none' ? undefined : (border1) ? `5px solid ${fill}` : undefined,
        background: card !== 'none' ? back : undefined,
        borderRadius: card === 'rounded' ? `${font / 3}rem` : undefined,
        padding: '10px 10px 10px 10px'
    }
    const d = xor((orientation === ''), !!(i % 2)) ? 'row' : 'row-reverse';
    return <div style={style} className={`d-flex flex-${d}  vivify animationObject fadeIn${ori} `}>
        <div className="d-flex justify-content-center pl-2 pr-2">
            <Icon2 icon={icon} font={font} fill={fill} back={iback} border={border2} aux={aux} />
        </div>
        <Html html={html} style={{ color, width: "calc(100% - 75px)" }} className="caption h-100 p-1" />
    </div>
}
function xor(a: boolean, b: boolean): boolean {
    return (a !== b);
}