import { css } from '@emotion/css';
import { Container, FormGroup, Button } from 'react-bootstrap';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Invert } from '../Components/Invert';
import Input, { valCorreo } from '../Utils/Input';
import { post } from '../Utils/Post';
export default function Suscribir({ data }: { data: any }) {
    const { props: { input, inputText, button, buttonText, backgroundColor, padding } } = data;
    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [valid, setValit] = useState(false);
    const suscribirse = () => {
        if (nombre !== '' && correo !== '' && valCorreo(correo))
            post('/api/Web/Suscribirse/nuevo',
                { nombre, correo }, () => toast('GRACIAS POR SUSCRIBIRSE',
                    { type: toast.TYPE.SUCCESS }))
        else setValit(true)
    };
    return <div style={{ paddingBlock: padding, background: backgroundColor }}>
        <Container>
            <Invert data={data}>
                <div className={getCSS(input, inputText, button, buttonText)}>
                    <FormGroup>
                        <Input valid={valid} value={nombre} setData={setNombre} placeholder="Ingresa tu nombre"
                            title={!valid ? "Ingresa tu nombre" : "Debe colocar el nombre"} />
                        <Input valid={valid} value={correo} setData={setCorreo} placeholder="Ingresa tu correo"
                            title={!valid ? "Ingresa tu correo" : "Debe colocar el correo"}
                            id='correo' next={suscribirse} />
                        <Button onClick={suscribirse} className='btn-block' size='lg'>Suscribirse</Button>
                    </FormGroup>
                </div>
            </Invert>
        </Container>
    </div>
}
function getCSS(color1: string, color2: string, color3: string, color4: string) {
    return css`
    margin: .25rem .25rem .25rem .25rem !important;
    padding: 0 0 0 0 !important;
    &>div> input {
        width: 100% !important;
        border-radius: 0 !important;
        &:first-child{border-radius: .3rem .3rem 0 0 !important;}
        ${toCSS(color1, color2)}
    }
    &>div> button {
        ${toCSS(color3, color4)}
        border: 0 !important;
        border-radius: 0 0 .3rem .3rem !important;
    }
    `;
}
function toCSS(back: string, text: string) {
    return `
    background: ${back} !important;
    color: ${text} !important;
    border: 2px solid ${text} !important;
    font-weight: 700 !important;
    &::placeholder{
        opacity: .5 !important;
        color: ${text} !important;
        font-weight: 500 !important;
    }`

}

