import { useContext } from "react";
import { Button, Image, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SeccionContext } from "../../../Template/Seccion";
import { getImagenURL } from "../../../Utils/Imagen";

export default function Template() {
    const { data: { User: { usuario, correo } }, logout } = useContext(SeccionContext);
    return <>
        <li className="user-header pb-3">
            <Image roundedCircle height={200} src={getImagenURL("/logos/user.jpg")} className="elevation-2" alt="Logo" />
        </li>
        <li className="user-header pb-3"><b>Nombre de Usuario:</b><br />{usuario}</li>
        <li className="user-header pb-3"><b>Correo Electronico:</b><br />{correo}</li>
        <li className="user-footer">
            <ButtonGroup vertical className="btn-block">
                <Button as={Link} to='/mis/datos' variant="outline-info" block className="btn-flat">Mis datos</Button>
                <Button onClick={() => logout()} variant="outline-danger" block className="btn-flat">Salir</Button>
            </ButtonGroup>
        </li>

    </>
}