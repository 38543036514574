import { toast } from "react-toastify";
import { Conexion } from "../Config/Coneccion";
import { isLine } from "./OnLineCheker";

class ConfigConeccion extends Conexion {
    config: string;
    clase = 'Config';
    constructor(config: string) {
        super();
        this.config = config;
    }
    pull = async (setData: (d: any) => void) => {
        try {
            const data = await this.getProxi(`/get/${this.config}`);
            if (Array.isArray(await data)) setData(await data);
        } catch (e) {
            if (isLine()) console.log(e);
        }
    }
    push(data: any, setData: (d: any) => void) {
        this.post('/api/Config/set/' + this.config, data, (d) => {
            setData(d)
            toast('CONFIGURACION GUARDADA EXITOSAMENTE', { type: toast.TYPE.SUCCESS })
        })
    }
}
export function ConConfig(config: string) {
    return new ConfigConeccion(config);
}