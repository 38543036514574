import { don } from "@victorequena22/conneccion";
import { toast } from "react-toastify";
const headers = { 'X-CSCAPI-KEY': 'ZE1NaERkV3JDekVRY3hWYlZsenUxWG1uNThidER3ZTVvR0hwRVEwRw==' };
const link = don + '/api/Commons'
class countrystatecity {
    async get<p>(url: string): Promise<p | null> {
        try {
            const cache = localStorage.getItem(url);
            if (cache !== null) return JSON.parse(cache) as p;
            const response = await fetch(link + url, { headers });
            const data = await response.json();
            localStorage.setItem(url, JSON.stringify(data));
            return await data as p;
        } catch (err) { console.log(err); }
        return null;
    }
    async getCountryList() {
        try {
            return await this.get<CountryList[]>('/paises');
        } catch (err) { console.log(err); }
        toast('ERROR AL CONECTAR CON LA API POR ESPERE UN POCO', { type: toast.TYPE.SUCCESS })
    }
    async getCountry(iso2: string) {
        try {
            return await this.get<CountryList>(`/pais/${iso2}`);
        } catch (err) { console.log(err); }
        toast('ERROR AL CONECTAR CON LA API POR ESPERE UN POCO', { type: toast.TYPE.SUCCESS })
    }
    async getCountryStates(iso2: string) {
        try {

            return await this.get<CountryList[]>(`/estados/${iso2}`);
        } catch (err) { console.log(err); }
        toast('ERROR AL CONECTAR CON LA API POR ESPERE UN POCO', { type: toast.TYPE.SUCCESS })
    }
    async getCountryState(iso: string, iso2: string) {
        try {
            return await this.get<CountryList>(`/estado/${iso}/${iso2}`);
        } catch (err) { console.log(err); }
        toast('ERROR AL CONECTAR CON LA API POR ESPERE UN POCO', { type: toast.TYPE.SUCCESS })
    }
}
export function dataContry() {
    return new countrystatecity();
}
export interface CountryList {
    phone: number;
    name: string;
    iso2: string;
}