import { useContext } from "react";
import { Dropdown, NavItem, Image } from "react-bootstrap";
import { SeccionContext } from "../../../Template/Seccion";
import Login from "./Login";
import Template from "./Template";
import { getImagenURL } from "../../../Utils/Imagen";
import { css } from "@emotion/css";
import { menu } from "@/Data";

export default function User() {
    const { data: { seccion } } = useContext(SeccionContext);
    const { backgroundColor, color } = menu;
    return <Dropdown as={NavItem} className='ml-1'>
        <Dropdown.Toggle variant="outline-dark" >
            <Image roundedCircle height={27} src={getImagenURL("/logos/user.jpg")} className="elevation-2" alt="Logo2" />
        </Dropdown.Toggle>
        <Dropdown.Menu className={`p-3 menu-login ${getCSS2(backgroundColor, color)}`}
            style={{ transform: 'translateX(calc(70px - 100%))' }}>
            {seccion ? <Template /> : <Login />}
        </Dropdown.Menu>
    </Dropdown >;

}
export function getCSS2(background = '#ffffff', colorFont = '#000000') {
    return css`&> li{
    color: ${colorFont} !important;
    &>.MuiFormControl-root{
            &>.MuiFormLabel-root{
                color: ${colorFont} !important;
            }
            &>.MuiInputBase-root{
                &>.MuiOutlinedInput-notchedOutline{
                    border-color: ${colorFont} !important;
                }
                &>fieldset{
                    background: ${background} !important;
                    color: ${colorFont} !important;
                }
                &>input{
                    z-index: 1;
                    background: transparent !important;
                    color: ${colorFont} !important;
                    border-color: ${colorFont} !important;
                    font-weight: bold;
                }
            }
        }
    }`;
}