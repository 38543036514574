// import { Suspense, lazy } from 'react';
// import { Suspense } from 'react';
import Cargando from '../Components/Cargando';
import { panels } from '../Data';
import Cargos from './Cargos';
import Carrusel from './Carrusel';
import Contact from './Contact';
import Galeria from './Galeria';
import Imagen from './Imagen';
import Mapa from './Mapa';
import Paralax1 from './Paralax1';
import Paralax2 from './Paralax2';
import Pestanas from './Pestanas';
import Preguntas from './Preguntas';
import Servicio2 from './Servicio2';
import Servicios from './Servicios';
import Suscribir from './Suscribir';
import Title from './Title';
import Video from './Video';
import Eventos from './Eventos';
import Programas from './Programas';
import ImagenSola from './ImagenSola';
import Testimonio from './Testimonios';
import BannerImagen from './BannerImagen';
import CardText from './CardText';
import CarruselCardText from './CarruselCardText';
import Facilitadores from './Facilitadores';
// const Cargos = lazy(() => import('./Cargos'));
// const Carrusel = lazy(() => import('./Carrusel'));
// const Imagen = lazy(() => import('./Imagen'));
// const Mapa = lazy(() => import('./Cargos'));
// const Paralax1 = lazy(() => import('./Paralax1'));
// const Paralax2 = lazy(() => import('./Paralax2'));
// const Pestanas = lazy(() => import('./Pestanas'));
// const Preguntas = lazy(() => import('./Preguntas'));
// const Servicios = lazy(() => import('./Servicios'));
// const Servicio2 = lazy(() => import('./Servicio2'));
// const Suscribir = lazy(() => import('./Suscribir'));
// const Title = lazy(() => import('./Title'));
// const Video = lazy(() => import('./Video'));
// const Contact = lazy(() => import('./Contact'));
// const Galeria = lazy(() => import('./Galeria'));
// const Eventos = lazy(() => import('./Eventos'));
// const Programas = lazy(() => import('./Programas'));
// const ImagenSola = lazy(() => import('./ImagenSola'));
// const Testimonio = lazy(() => import('./Testimonios'));
// const BannerImagen = lazy(() => import('./BannerImagen'));

export function Preview({ panel }: { panel: any }) {
    return <>
        {/* <Suspense fallback={<Cargando />}> */}
        <Panel panel={panel} />
        {/* </Suspense> */}
    </>
}
export function Panel({ panel }: { panel: any }) {
    if (panel === undefined) return <></>
    switch (panel.type) {
        case 'cargos': return <Cargos key={'panel' + panel.id} data={panel} />
        case 'carrusel': return <Carrusel key={'panel' + panel.id} data={panel} />
        case 'galeria': return <Galeria key={'panel' + panel.id} data={panel} />
        case 'imagen': return <Imagen key={'panel' + panel.id} data={panel} />
        case 'imagenSola': return <ImagenSola key={'panel' + panel.id} data={panel} />
        case 'mapa': return <Mapa key={'panel' + panel.id} data={panel} />
        case 'paralax1': return <Paralax1 key={'panel' + panel.id} data={panel} />
        case 'paralax2': return <Paralax2 key={'panel' + panel.id} data={panel} />
        case 'pestanas': return <Pestanas key={'panel' + panel.id} data={panel} />
        case 'preguntas': return <Preguntas key={'panel' + panel.id} data={panel} />
        case 'servivio1': return <Servicios key={'panel' + panel.id} data={panel} />
        case 'servivio2': return <Servicio2 key={'panel' + panel.id} data={panel} />
        case 'suscribir': return <Suscribir key={'panel' + panel.id} data={panel} />
        case 'contacto': return <Contact key={'panel' + panel.id} data={panel} />
        case 'titulo': return <Title key={'panel' + panel.id} data={panel} />
        case 'video': return <Video key={'panel' + panel.id} data={panel} />
        case 'eventos': return <Eventos key={'panel' + panel.id} data={panel} />
        case 'programas': return <Programas key={'panel' + panel.id} data={panel} />
        case 'testimonio': return <Testimonio key={'panel' + panel.id} data={panel} />
        case 'BannerIMG': return <BannerImagen key={'panel' + panel.id} data={panel} />
        case 'CardText': return <CardText key={'panel' + panel.id} data={panel}  />
        case 'cargosCT': return <CarruselCardText key={'panel' + panel.id} data={panel} />
        case 'TEIFacil': return <Facilitadores key={'panel' + panel.id} data={panel} />
        default: return <Cargando />
    }
}

export default function PageView() {
    return <>{panels.filter(({ estado }) => estado === 1)
        .map(p => <div key={p.type + p.id} className='w-100 m-0 p-0'>
            <Preview panel={p} />
        </div>)}</>
}
