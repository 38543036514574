import { useCallback, useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Col, FormGroup, Image, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import InputFild from "../../../Components/Material/InputFild";
import { don } from "../../../Config/Coneccion";
import { ConConfig } from "../../../Config/Config";
import { SeccionContext } from "../../../Template/Seccion";
import ModalRegister from "../Modales/ModalRegister";
interface Props {
    open: boolean,
    close: () => void,
}
export default function ModalLogin({ close, open }: Props) {
    const { user } = useContext(SeccionContext)
    const [usuario, setUsuario] = useState('');
    const [clave, setClave] = useState('');
    const [nuevo, setNuevo] = useState(false);
    const [error, setError] = useState<any>({});
    const send = useCallback(() => {
        ConConfig('').post(don + '/api/Participante/login', { usuario, clave }, (data) => {
            localStorage.setItem('Authorization', data.token);
            user();
        }, e => {
            const error: any = {};
            e.forEach((e: any) => {
                if (e.sql) {
                    e.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
                } else {
                    Object.keys(e).forEach(t => error[t] = e[t])
                }
            })
            setError(error);
        })
    }, [usuario, clave, user]);
    useEffect(() => setError({}), [usuario, clave])
    return <>
        <ModalRegister open={nuevo} close={() => setNuevo(false)} />
        <Modal show={open} centered onHide={close}>
            <Modal.Header closeButton>
                <h2 className="m-0 font-weight-bold text-primary mr-auto align-self-center">
                    <span><b>Login</b></span>
                </h2>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} md={{ span: 8, offset: 2 }}>
                        <Image fluid roundedCircle src="/logos/user.jpg" className="elevation-2" alt="Logo" />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <InputFild error={error.usuario} xs maxLength={200} setData={setUsuario} label="Usuario o correo" value={usuario} />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <InputFild type="password" error={error.clave} xs maxLength={200} setData={setClave} label="Contraseña" value={clave} />
                    </Col>
                    <Col as={FormGroup} xs={12}>
                        <ButtonGroup className="w-100">
                            <Button onClick={() => { setNuevo(true); close() }} variant="outline-primary" className="btn-flat w-50">Registrarse</Button>
                            <Button onClick={() => send()} variant="outline-info" className="btn-flat  w-50">Login</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    </>;

}