import { useCallback, useContext, useEffect, useState } from "react";
import { Button, FormGroup, Image } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { FieldControlText } from "../../../Components/Material/FieldControl";
import { SeccionContext, SeccionType } from "../../../Template/Seccion";
import { conParticipante, Participante } from "../Models";

export default function ClaveRecuperar({ par: { nombre, correo }, code }: { par: Participante, code: string }) {
    const { user, data: { seccion } } = useContext(SeccionContext) as SeccionType;
    const [clave, setClave] = useState('');
    const [reclave, setReClave] = useState('');
    const [error, setError] = useState<any>({});
    const [redirect, setRedirect] = useState(false);
    const send = useCallback(() => {
        let pro = true, error: any = {};
        if (clave === '') {
            pro = false;
            error.clave = 'Debe colocar una clave';
        }
        if (reclave === '') {
            pro = false;
            error.reclave = 'Debe repetir la clave';
        }
        if (pro && clave !== reclave) {
            pro = false;
            error.reclave = 'Las claves no son iguales';
        }
        if (pro) conParticipante().cambiarClave(
            { reclave, clave, code },
            s => success(s, user),
            e => setErr(e, setError)
        ); else setError(error);
    }, [clave, user, reclave, code]);
    useEffect(() => setError({}), [clave, reclave]);
    useEffect(() => { if (seccion) setTimeout(() => setRedirect(true), 5000); }, [seccion])
    const input = { type: "password", maxLength: 200 };
    return <>
        {redirect ? <Redirect to='/' /> : <></>}
        <FormGroup className="pb-3 text-center">
            <Image roundedCircle height={200} src="/logos/user.jpg" className="elevation-2" alt="Logo" />
        </FormGroup>
        <FormGroup as='h3' className="text-center">{nombre}</FormGroup>
        <FormGroup as='h3' className="text-center">{correo}</FormGroup>
        {seccion ? <>
            <FormGroup as='h3' className="text-center text-success">CLAVE CAMBIADA</FormGroup>
        </> : <>
            <FormGroup>
                <FieldControlText input={input} error={error.clave} setData={setClave} label="Contraseña" value={clave} />
            </FormGroup>
            <FormGroup>
                <FieldControlText input={input} error={error.reclave} setData={setReClave} label="Repetir contraseña" value={reclave} />
            </FormGroup>
            <FormGroup >
                <Button onClick={() => send()} block variant="outline-success" className="btn-flat">Login</Button>
            </FormGroup>
        </>}

    </>;

}
const success = (s: any, user: () => void) => {
    localStorage.setItem('Authorization', s.token);
    user();
}
const setErr = (e: any, setError: (d: any) => void) => {
    const error: any = {};
    e.forEach((e: any) => {
        if (e.sql) {
            e.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
        } else {
            Object.keys(e).forEach(t => error[t] = e[t])
        }
    })
    setError(error);
}