import { CSSProperties } from "react";
import './HTML.css';
import { css } from "@emotion/css";
import { url } from "@/Data";
import { Link } from "react-router-dom";
import { don } from "@victorequena22/conneccion";
interface Props {
    className?: string;
    style?: CSSProperties;
    html: any[];
    ref?: any
}
export function Html({ ref, className, style, html }: Props) {
    return <div ref={ref} style={style} className={className} >
        {html?.map(e => <VerElement key={`keyHTML${Math.random()}`} element={e}>
            {e.children.map((a: any) => <VerLeaf key={`keyLeaf${a.text}`} leaf={a} />)}
        </VerElement>)}
    </div>
}
export const VerLeaf = ({ leaf }: { leaf: any }) => {
    const { color, backgroundColor, fontFamily, fontSize, bold, italic, underline, text } = leaf
    const style = {
        color,
        fontFamily,
        backgroundColor,
        fontSize: fontSize ? `${fontSize / 10}rem` : undefined,
        fontWeight: bold ? 'bold' : 'normal',
        fontStyle: italic ? 'italic' : 'normal',
        textDecoration: underline ? 'underline' : 'none',
    }
    return <span style={style} dangerouslySetInnerHTML={{ __html: text.replaceAll('¤', '"') }} />
}
export const VerElement = (p: { element: any; children: any }) => <List {...p} />

const List = (p: any) => {
    const { children, element, } = p;
    return <Block element={element}>
        <ListElement element={element}>
            {children}
        </ListElement>
    </Block>
}
const Block = ({ element, children }: { element: any; children: any }) => {
    switch (element.type) {
        case 'heading-one':
            return <h1 style={getCSS(element)}>{children}</h1>
        case 'heading-two':
            return <h2 style={getCSS(element)}>{children}</h2>
        case 'heading-tre':
            return <h3 style={getCSS(element)}>{children}</h3>
        case 'heading-for':
            return <h4 style={getCSS(element)}>{children}</h4>
        default:
            return <p style={getCSS(element)}>{children}</p>
    }
}
export function TextToHTML({ text }: { text: any[] }) {
    return <>
        {text.map((e) => (
            <VerElement key={JSON.stringify(e)} element={e}>
                {e.children.map((a: any) => (
                    <VerLeaf key={a.text} leaf={a} />
                ))}
            </VerElement>
        ))}
        <span style={{ color: "#0000" }}>|</span>
    </>
}

const getCSS = ({ align, tabs, type, children }: any): CSSProperties => {
    const font = getFontUsa(type, children[0]) / 10;
    const t2 = tabs * 2;
    const color = children[0] ? children[0].color : '#000';
    return {
        float: 'right', textAlign: align, padding: '0 0 0 0', fontSize: `${font}rem`, minHeight: `${font}rem`,
        width: `calc(100% - ${t2}rem)`, margin: '.05rem .05rem .05rem .05rem', color, display: 'flex',
        justifyContent: align
    }
}
const getFontSize = (type: any) => {
    if (type === 'heading-one') return 26
    if (type === 'heading-two') return 22
    if (type === 'heading-tre') return 18
    if (type === 'heading-for') return 14
    return 10
}
const getFontUsa = (type: any, children?: any) => {
    const b = getFontSize(type)
    const c = children?.fontSize;
    if (c) return c < b ? b : c;
    return b;
}

const ListElement = ({ element, children }: { element: any, children: any }) => {
    const { list, type } = element;
    if (type === 'button') return <Button element={element}>{children}</Button>
    return <div style={list === 'paragraph' ? getCSSNoList(element) : getCSSList(element)}>{children}</div>

}
function Button({ element, children }: { element: any, children: any }) {
    const { link } = element;
    const url = useLink(link);
    const css = getCSSBotton(element);
    if (link && valInterno(url)) return <Link to={getInterno(url)} className={css}>{children}</Link>;
    return <a href={url} target='_blank' rel="noreferrer" className={css}>{children}</a>
}
const getCSSBotton = ({ align, background, hover, borderColor, borderHover, borderSize = 0, borderRadius = 0 }: any): string => {
    return `btn ${align === 'justify' ? 'btn-block' : ''} ` + css`
    background: ${background} !important;
    border: ${borderSize}px solid ${borderColor} !important;
    border-radius: ${borderRadius}px !important;
    &:hover { 
        background: ${hover} !important; 
        border-color: ${borderHover} !important;
    }
    &:active { 
        background: ${background} !important; 
        border-color: ${borderColor} !important;
    }
    `;
}

const getCSSList = ({ tabs, type, list, children, align }: any): CSSProperties => {
    const font = getFontUsa(type, children[0]) / 10;
    const t2 = tabs * 2;
    const t = list === 'paragraph' ? t2 : t2 + font
    const s = list === 'paragraph' ? {} : { display: 'list-item', listStyleType: list === 'bulleted-list' ? 'disc' : 'square' }
    return {
        padding: '0 0 0 0', width: `fit-content`, ...s, marginLeft: `${t}rem`, textAlign: align
    }
}
const getCSSNoList = ({ align }: any): CSSProperties => {
    return {
        padding: '0 0 0 0', width: `fit-content`, textAlign: align
    }
}
const valInterno = (u: string) => {
    const int = (new URL(url)).hostname;
    const v = (new URL(u)).hostname;
    return int === v;
}
const getInterno = (u: string) => {
    return u.replaceAll(don, '/').replaceAll('//', '/').replaceAll('//', '/');
}
function useLink(u: string) {
    if (u === undefined) return '';
    if (valURL(u)) return u;
    return `${don}/${u.replaceAll('//', '/')}`;
}
const valURL = (u: string) => {
    var patronURL = new RegExp(
        // valida protocolo
        '^(https?:\\/\\/)?' +
        // valida nombre de dominio
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
        // valida OR direccion ip (v4)
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        // valida puerto y path
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        // valida queries
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        // valida fragment locator
        '(\\#[-a-z\\d_]*)?$', 'i');
    return !!patronURL.test(u.toLowerCase());
}