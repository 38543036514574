import { Card, Col } from "react-bootstrap";
import { css } from '@emotion/css';
import { isLogin } from "../../../Template/Seccion";
export default function Suscribirse({ html, config, Participante, Telefono }: { html: any, config: any, Participante: any, Telefono: any }) {
    const i = isLogin();
    const { online = false } = config;
    return <Col xs={12} md={5} xl={4} className="p-xs p-sm  p-all">
        <Card className={`${getCSS(config)} p-0 m-0`} id='Suscribir'>
            {i ? <Participante html={html} /> : <Telefono online={online} html={html} />}
        </Card>
    </Col>
}
// export function getCSS2({ colorFF = '#ffffff', colorFC = '#ffffff', colorBC = '#0069d9', colorTC = '#000000', colorBT = '#ffffff', fontC = 'Arial', fontB = 'Arial' }: any) {
//     return css`
//     background: ${colorFF};
//     &>.row>.text{
//         color: ${colorTC} !important;
//         font-family: ${fontC} !important;
//         font-weight: bold;
//     }
//     &>.row>.boton>.btn {
//         background: ${colorBC};
//         border-color: ${colorBC};
//         color: ${colorBT};
//         font-family: ${fontB} !important;
//     }
//     &>.row>.boton>.btn:hover {
//         opacity: .7 !important;
//     }
//     &>.row>.boton>.btn:active {
//         opacity: 1 !important;
//     }`;
// }


export function getCSS({ colorFF = '#ffffff', colorFC = '#ffffff', colorBC = '#0069d9', colorTC = '#000000', colorBT = '#ffffff', fontC = 'Arial', fontB = 'Arial' }: any) {
    return css`
    background: ${colorFF};
    &>.row{
        &>.input{
            &>.MuiFormControl-root{
                &>.MuiInputBase-root{
                    &>.MuiOutlinedInput-notchedOutline{
                        border-color: ${colorTC} !important;
                    }
                    &>fieldset>legend{
                        display:none!important;
                    }
                    &>fieldset{
                        background: ${colorFC} !important;
                    }
                    &>input,
                    &>select,
                    &>select>option{
                        z-index: 1;
                        background: transparent !important;
                        color: ${colorTC} !important;
                        font-family: ${fontC} !important;
                        border-color: ${colorTC} !important;
                        font-weight: bold;
                    }
                }
                &>.MuiFormLabel-root{
                    background-color: transparent !important;
                    font-weight: bold;
                }
                &>.MuiInputLabel-shrink{
                    z-index: 2;
                    transform: translateX(10px) scale(0.5);
                }
                &>.Mui-error,{
                    color: #d32f2f !important;
                    &>.MuiOutlinedInput-notchedOutline{
                            border-color: #d32f2f !important;
                    }
                }
            }
            &>.MuiBox-root{
                background: ${colorFC} !important;
                border: ${colorTC} solid 1px !important;
                margin-top: -5px;
                padding-top: 5px;
                padding-left: 5px;
                border-radius: 4px;
                &>span{
                    z-index: 1;
                    padding-top: 8px;
                    width: 45px;
                    background: transparent !important;
                    color: ${colorTC} !important;
                    font-family: ${fontC} !important;
                    font-weight: bold;
                }
                &>.MuiFormControl-root{
                    &>.MuiInputBase-root{
                        &>.MuiOutlinedInput-notchedOutline{
                            border-color: ${colorTC} !important;
                            font-weight: bold;
                        }
                        &>fieldset{
                            display:none!important;
                        }
                        &>input,
                        &>select,
                        &>select>option{
                            z-index: 1;
                            padding-left: 0;
                            background: transparent !important;
                            color: ${colorTC} !important;
                            font-family: ${fontC} !important;
                            font-weight: bold;
                        }
                    }
                    &>.MuiFormLabel-root{
                        margin-left: -51px;
                        background: ${colorFC} !important;
                        font-weight: bold;
                    }
                    &>.MuiInputLabel-shrink{
                        z-index: 2;
                        background-color: transparent !important;
                        transform: translateX(10px) scale(0.5);
                    }
                }
            }
            &>.MuiFormHelperText-root{
                color: #d32f2f !important;
                padding-left: 15px;
                &>.MuiOutlinedInput-notchedOutline{
                        border-color: #d32f2f !important;
                }
            }
        }
        &>.text{
            color: ${colorTC} !important;
            font-family: ${fontC} !important;
            font-weight: bold;
        }
        &>.boton{
            &>.btn {
                background: ${colorBC};
                border-color: ${colorBC};
                color: ${colorBT};
                font-family: ${fontB} !important;
            }
            &>.btn:hover {
                opacity: .7 !important;
            }
            &>.btn:active {
                opacity: 1 !important;
            }
        }
    }`;
}

