import { FormControl, InputLabel, FormHelperText, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { Button, Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { ContrySelector, StateSelector } from "../../../Components/Contry";
import InputFild from "../../../Components/Material/InputFild";
import { validarEmail } from "../../../Utils/Formatos";
import { Participante } from "../Models";
import React from "react";
import { css } from '@emotion/css';
export interface State extends Participante {
    confirmarClave: string;
    error: any;
}
interface Props {
    data: State,
    error: any,
    setData: (d: Partial<State>) => void
    save: () => void
}
export default function From({ save, error, data: { confirmarClave, nombre, nacimiento, pais, estado, ciudad, direccion, telefono, correo, usuario, clave, Profesión, Ocupación }, setData, }: Props) {
    return <Row>
        <Col as={FormGroup} md={12}><InputFild maxLength={200} error={error} data="nombre" setData={setData} label="Nombre Completo*" value={nombre} /></Col>
        <Col as={FormGroup} md={8}><InputFild maxLength={200} error={error} data="correo" setData={setData} label="Correo Electronico*" value={correo} /></Col>
        <Col as={FormGroup} md={4}><InputFild mask="9999-99999999" error={error} data="telefono" setData={setData} label="Teléfono*" value={telefono} /></Col>
        <Col className={css`@media (min-width: 768px) {padding-top: 2rem}`} as={FormGroup} md={8}>
            <InputFild mask="@********************************" error={error} data="usuario" setData={setData} label="Nombre de Usuario*" value={usuario} />
        </Col>
        <Col as={FormGroup} md={4}>
            <Col className="p-0 mb-2" md={12}>Fecha de Nacimiento*</Col>
            <Nacimiento error={error.nacimiento} nacimiento={nacimiento} setData={nacimiento => setData({ nacimiento })} />
        </Col>
        <Col as={FormGroup} md={6}><InputFild type="password" error={error} data="clave" setData={setData} label="Contraseña*" value={clave} /></Col>
        <Col as={FormGroup} md={6}><InputFild type="password" error={error} data="confirmarClave" setData={setData} label="Repetir contraseña*" value={confirmarClave} /></Col>
        <Col as={FormGroup} xs={12}>
            <FormLabel>DIRECCIÓN</FormLabel>
            <Row className="mb-1">
                <Col className="mt-1" md={3}><ContrySelector error={error.pais} defaultValue={pais} change={pais => setData({ pais })} /></Col>
                <Col className="mt-1" md={3}><StateSelector error={error.estado} pais={pais} defaultValue={estado} change={estado => setData({ estado })} /></Col>
                <Col className="mt-1" md={6}><InputFild maxLength={100} error={error} data="ciudad" setData={setData} label="Ciudad*" value={ciudad} /></Col>
            </Row>
            <InputFild maxRows={5} maxLength={500} error={error} data="direccion" setData={setData} label="Dirección" value={direccion} />
        </Col>
        <Col as={FormGroup} md={12}><InputFild maxLength={200} error={error} data="Profesión" setData={setData} label="Profesión" value={Profesión} /></Col>
        <Col as={FormGroup} md={12}><InputFild maxLength={200} error={error} data="Ocupación" setData={setData} label="Ocupación" value={Ocupación} /></Col>
        <Col as={FormGroup} md={12} className="text-right"><Button onClick={save} variant="outline-success">Registrarse</Button></Col>
    </Row>
}
export class Prototipo<p> extends React.Component<p, State> {
    procesar = true;
    render = () => {
        const { state, setData, save } = this;
        return (<From save={save} data={state} setData={setData} error={state.error} />)
    }
    setData = (p: Partial<State>) => {
        const error: any = this.state.error;
        Object.keys(p).forEach(label => {
            error[label] = undefined;
        });
        this.setState({ error });
        this.setState(p as any);
    };
    save = () => { };
    validar = () => {
        const error: any = {};
        let procesar = true;
        const { confirmarClave, nombre, nacimiento, pais, estado, ciudad, telefono, correo, usuario, clave } = this.state;
        if (nombre === '') {
            procesar = false;
            error.nombre = 'Debe ingresar su nombre completo';
        }
        if (pais === '') {
            procesar = false;
            error.pais = 'Debe seleccionar un pais';
        }
        if (estado === '') {
            procesar = false;
            error.estado = 'Debe seleccionar un estado';
        }
        if (ciudad === '') {
            procesar = false;
            error.ciudad = 'Debe ingresar la ciudad';
        }
        if (usuario === '') {
            procesar = false;
            error.usuario = 'Debe ingresar un numbre de usuario';
        }
        if (clave === '') {
            procesar = false;
            error.clave = 'Debe ingresar una clave';
            error.confirmarClave = 'Debe ingresar una clave';
        } else if (confirmarClave !== clave) {
            procesar = false;
            error.confirmarClave = 'Las claves no coinciden';
        }
        if (telefono === '') {
            procesar = false;
            error.telefono = 'Debe ingresar un numero de telefono';
        }
        if (!validarEmail(correo)) {
            procesar = false;
            error.correo = 'No es un correo valido';
        }
        if (nacimiento === '') {
            procesar = false;
            error.nacimiento = 'Debe ingresar su fecha de nacimiento';
        }
        this.setData({ error });
        return procesar;
    }
    getData() {
        const { confirmarClave, nombre, nacimiento, pais, estado, ciudad, direccion, telefono, correo, usuario, clave, Profesión, Ocupación } = this.state;
        return { confirmarClave, nombre, nacimiento, pais, estado, ciudad, direccion, telefono, correo, usuario: usuario.replace('@', ''), clave, Profesión, Ocupación };
    }

    setError = (err: any[]) => {
        this.procesar = true;
        const error: any = {};
        err.forEach((e: any) => {
            if (e.sql) {
                e.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
            } else {
                Object.keys(e).forEach(t => error[t] = e[t])
            }
        })
        this.setData({ error });
        this.forceUpdate();
    }
}

const meses = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const t = css`@media (max-width: 768px) {padding-left: 0;padding-right: 0;}`;
const k = css`@media (max-width: 768px) {padding-top: .5rem !important;}`;
export function Nacimiento({ error, nacimiento, setData }: { error: any, nacimiento: string, setData: (d: string) => void }) {
    let dia = 0, mes = 0, ano = 0;
    if (nacimiento !== '') {
        const f = nacimiento.split('-');
        dia = parseInt(f[2]);
        mes = parseInt(f[1]);
        ano = parseInt(f[0]);
    }
    return <Col className="m-0 p-0" as={FormControl} aria-describedby="nacimiento-error" error={error} md={12}>
        <Row className="m-0 p-0">
            <Col className={"p-0 " + k} md={3}><InputFild error={error ? ' ' : false} mask="99" label="Dia" value={dia + ''} setData={dia => setData(`${ano}-${mes}-${dia}`)} /></Col>
            <Col className={t + ' ' + k} md={5}><MesSelector error={error} value={mes} change={mes => setData(`${ano}-${mes}-${dia}`)} /></Col>
            <Col className={"p-0 " + k} md={4}><AnoSelector error={error ? ' ' : false} value={ano} change={ano => setData(`${ano}-${mes}-${dia}`)} /></Col>
        </Row>
        {error ? <FormHelperText id="nacimiento-error">{error}</FormHelperText> : <></>}
    </Col>

}
function MesSelector({ change, value, error }: { change: (i: number) => void, value: number, error: any, }) {
    const set = (e: SelectChangeEvent) => change(parseInt(e.target.value as any));
    return <FormControl style={{ width: '100%' }} error={error}>
        <InputLabel htmlFor="nacimiento">Mes</InputLabel>
        <Select value={value + ''} label="Mes" onChange={set}>
            {meses.map((m, i) => <MenuItem key={i} value={i}>{m}</MenuItem>)}
        </Select>
    </FormControl>
}

function AnoSelector({ change, value, error }: { change: (i: number) => void, value: number, error: any, }) {
    const set = (e: SelectChangeEvent) => change(parseInt(e.target.value as any));
    const ano = (new Date()).getFullYear();
    const opt = [];
    for (let i = 1950; i <= ano; i++) {
        opt.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
    }
    return <FormControl style={{ width: '100%' }} error={error}>
        <InputLabel htmlFor="nacimiento">Año</InputLabel>
        <Select value={value + ''} label="Año" onChange={set}>
            {opt}
        </Select>
    </FormControl>
}
