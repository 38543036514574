import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { conCapacitacion } from "../../Models";
import { Col, Row, FormGroup, Button } from "react-bootstrap";
import { programToId } from "../../../Context/Programas";
import { ContrySelector, StateSelector } from "../../../../Components/Contry";
import { FieldControlText } from "../../../../Components/Material/FieldControl";

export default function Telefono() {
    const id = programToId(useParams<{ id: any }>().id);
    const [error, setError] = useState<any>({});
    const [pais, setPais] = useState('');
    const [estado, setEstado] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [telefono, setTelefono] = useState('');
    const [correo, setCorreo] = useState('');
    const [nombre, setNombre] = useState('');
    const [suscrito, setSuscrito] = useState(false);
    const send = useCallback(() => {
        var val = true, error: any = {};
        if (pais === '') { error.pais = 'Debe seleccionar un pais'; val = false; }
        if (estado === '') { error.estado = 'Debe seleccionar un estado'; val = false; }
        if (ciudad === '') { error.ciudad = 'Debe ingresar una ciudad'; val = false; }
        if (telefono === '') { error.telefono = 'Debe ingresar un telefono'; val = false; }
        if (correo === '') { error.correo = 'Debe ingresar un correo'; val = false; }
        if (nombre === '') { error.nombre = 'Debe ingresar un nombre'; val = false; }
        if (val) conCapacitacion().Suscribir(id, { pais, correo, estado, ciudad, telefono, nombre }, () => setSuscrito(true))
        else setError(error);
    }, [pais, estado, ciudad, telefono, correo, nombre, id]);
    useEffect(() => {
        var e: any = {};
        if (pais === '' && error.pais) { e.pais = error.pais; }
        if (estado === '' && error.estado) { e.estado = error.estado; }
        if (ciudad === '' && error.ciudad) { e.ciudad = error.ciudad; }
        if (telefono === '' && error.telefono) { e.telefono = error.telefono; }
        if (nombre === '' && error.nombre) { e.nombre = error.nombre; }
        if (JSON.stringify(e) !== JSON.stringify(error)) setError(e);
    }, [pais, estado, ciudad, telefono, nombre, error])
    return suscrito ? <h3 className="text-success">Gracias por suscribirte</h3> : <Row>
        <Col as={FormGroup} md={12}><h4 className="text-info"><b>Suscríbete para recibir información de las próximas fechas:</b></h4></Col>
        <Col as={FormGroup} md={12}><FieldControlText input={{ maxLength: 200, }} error={error.nombre} setData={setNombre} label="Nombre*" value={nombre} /></Col>
        <Col as={FormGroup} md={8}><FieldControlText input={{ maxLength: 200, }} error={error.correo} setData={setCorreo} label="Correo Electronico*" value={correo} /></Col>
        <Col as={FormGroup} md={4}><FieldControlText mask="9999-99999999" error={error.telefono} setData={setTelefono} label="Whatsapp*" value={telefono} /></Col>
        <Col as={FormGroup} md={3}><ContrySelector error={error.pais} defaultValue={pais} change={setPais} /></Col>
        <Col as={FormGroup} md={3}><StateSelector error={error.estado} pais={pais} defaultValue={estado} change={setEstado} /></Col>
        <Col as={FormGroup} md={6}><FieldControlText input={{ maxLength: 100 }} error={error.ciudad} setData={setCiudad} label="Ciudad*" value={ciudad} /></Col>
        <Col as={FormGroup} md={12}><Button block variant="primary" onClick={() => send()}>Suscribirse</Button></Col>
    </Row>
}