import { useCallback, useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, FormGroup, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import InputFild from "../../../Components/Material/InputFild";
import { ConConfig } from "../../../Config/Config";
import { SeccionContext } from "../../../Template/Seccion";
import ModalRecuperar from "../Modales/ModalRecuperar";
import ModalRegister from "../Modales/ModalRegister";
import { getImagenURL } from "../../../Utils/Imagen";
import { don } from "@victorequena22/conneccion";

export default function Login() {
    const { user } = useContext(SeccionContext)
    const [usuario, setUsuario] = useState('');
    const [clave, setClave] = useState('');
    const [open, setOpen] = useState(false);
    const [recuperar, setRecuperar] = useState(false);
    const [error, setError] = useState<any>({});
    const send = useCallback(() => {
        const u = usuario.trimEnd().trimStart();
        ConConfig('').post(don + '/api/Participante/login', { usuario: u, clave }, (data) => {
            localStorage.setItem('Authorization', data.token);
            user();
        }, e => {
            const error: any = {};
            e.forEach((e: any) => {
                if (e.sql) {
                    e.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
                } else {
                    Object.keys(e).forEach(t => error[t] = e[t])
                }
            })
            setError(error);
        })
    }, [usuario, clave, user]);
    useEffect(() => setError({}), [usuario, clave])
    return <>
        <ModalRecuperar open={recuperar} close={() => setRecuperar(false)} />
        <ModalRegister open={open} close={() => setOpen(false)} />
        <li className="user-header pb-3 text-center">
            <Image roundedCircle height={200} src={getImagenURL("/logos/user.jpg")} className="elevation-2" alt="Logo" />
        </li>
        <FormGroup as='li'>
            <InputFild error={error.usuario} maxLength={200} setData={setUsuario} label="Usuario o correo" value={usuario} />
        </FormGroup>
        <FormGroup as='li'>
            <InputFild type="password" error={error.clave} maxLength={200} setData={setClave} label="Contraseña" value={clave} />
        </FormGroup>
        <li className="user-footer">
            <ButtonGroup vertical className="w-100">
                <ButtonGroup className="w-100">
                    <Button onClick={() => setOpen(true)} variant="outline-primary" className="btn-flat w-50">Registrarse</Button>
                    <Button onClick={() => send()} variant="outline-success" className="btn-flat  w-50">Login</Button>
                </ButtonGroup>
                <Button onClick={() => setRecuperar(true)} block variant="outline-info" className="btn-flat">Recuperar clave</Button>
            </ButtonGroup>
        </li>
    </>;

}