import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { SeccionProvider } from './Template/Seccion';
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";


const rootElement:any = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
  <SeccionProvider><Router>
    <Switch><App /></Switch>
  </Router></SeccionProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
