import { lazy, Suspense } from 'react';
import Cargando from "@/Components/Cargando";
const Slider = lazy(() => import('react-slick'));


export default function SliderLazy({ children, settings: s, className }: { children: any, settings: any, className?: string }) {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        slidesToScroll: 1,
    }
    const p = { ...settings, ...s }
    return <Suspense fallback={<Cargando />}>
        <Slider {...p} className={className}>
            {children}
        </Slider>
    </Suspense>
}