import { Route } from "react-router-dom";
import VerFacilitador from './Facilitadores/Ver';
import LandingProgama from "./Programas/Landing";
import LandingFacilitador from "./Facilitadores/Landing";
import EspecialidadProvider from "./Facilitadores/Especialidades";
import Suscrito from "./Evento/Contactame/Subscrito";
import Contactame from "./Evento/Contactame/Participante";
import InscribirEvento from "./Evento/Ver";
import MainProgramas from "./Programas/Buscar/Main";
import MainEventos from "./Evento/Buscar/Main";
import Manager from "./Evento/Manager";


export function Capacitacion() {
    return <>
        <Route path='/programas' exact><MainProgramas /></Route>
        <Route path='/programa'><ProgramasMaster /></Route>
        <Route path='/evento'><EventosMaster /></Route>
        <Route path='/e'><EventosMaster /></Route>
        <Route path='/eventos'><MainEventos /></Route>
        <Route path='/facilitador'><FacilitadorMaster /></Route>
        <Route path='/f'><FacilitadorMaster /></Route>
    </>
}

export function ProgramasMaster() {
    return <>
        <Route path='/programa/:id' exact><LandingProgama /></Route>
    </>
}

export function EventosMaster() {
    return <>
        <Route path="/evento/inscribir/:id"><InscribirEvento /></Route>
        <Route exact path="/evento/:id"><Manager /></Route>
        <Route exact path="/e/sub/:id"><Suscrito /></Route>
        <Route exact path="/e/reg/:id"><Contactame /></Route>
    </>
}
export function FacilitadorMaster() {
    return <EspecialidadProvider>
        <Route path="/facilitador/:id"><LandingFacilitador /></Route>
        <Route path="/f/:id"><VerFacilitador /></Route>
    </EspecialidadProvider>
}