import { useState, useEffect } from "react";
import { PropsDel } from "../Props";
import { Button, FormCheck } from "react-bootstrap";
import RegistrarPago from "../../../Pagos/Registrar";
import { Encuesta } from "../Encuesta";
import { conEvento } from "../../Models";
import { findEncuesta } from "../../../../Template/Seccion";
export function PrimerPago({ init, evento }: PropsDel) {
    const encuesta = findEncuesta(evento.id)
    const [v, setv] = useState(false);//validacion Aceptar condiciones 
    const [a, setA] = useState(false);//Aceptar condiciones
    const [r, setR] = useState(false);//validacion de respuesta de la encuesta
    const [o, seto] = useState(false);//Abrir modal para pago
    const [respuesta, setRespuesta] = useState(encuesta ? encuesta.respuesta : '');//respuesta de la encuesta
    useEffect(() => { setv(false); setR(false) }, [respuesta, a])
    return <>
        <RegistrarPago update={init} evento={evento} open={o} close={() => seto(false)} />
        <p>
            <FormCheck checked={a} isInvalid={v} type="checkbox"
                onClick={() => { setA(!a); setv(false); }}
                feedback="Para poder inscribirse debe aceptar las normas y consideraciones"
                label={'Acepto las normas y consideraciones'}
            /><br />
            {!encuesta ? a ? <Encuesta respuesta={respuesta} setRespuesta={setRespuesta} isError={r} /> : <></> : <></>}
        </p>
        <Button block variant='success' className='m-auto' size="lg"
            onClick={() => {
                if (a && respuesta !== '') {
                    conEvento().encuesta(evento.id, respuesta, init);
                    seto(true)
                } else {
                    if (!a) setv(true)
                    if (respuesta === '') setR(true)
                }
            }} >
            Registrar Pago
        </Button>
    </>
}