import React from "react";
import { Button, Col, FormGroup, Image as Img, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { numberFormat } from "@victorequena22/utiles";
import { MetodoMostrar } from "../MetodosPago/MetodoSelector";
import { MetodoPago } from "../MetodosPago/Models";
import { conDolar, conPago, partialPago } from "../Models";
import { FieldControlDate, FieldControlNumber, FieldControlText } from "../../../Components/Material/FieldControl";
interface Props {
    dolar: number
    metodo: MetodoPago
    data: partialPago,
    setData: (p: Partial<partialPago>) => void
    error: any
    save: () => void
}
function From({ dolar, save, metodo, error, data: { banco, fecha, monto, referencia }, setData }: Props) {
    return <Row>
        <Col as={FormGroup} md={12}>
            <FieldControlDate error={error} update="fecha" setData={setData} label="Fecha de la operación*" value={fecha} />
        </Col>
        <Col as={FormGroup} md={12}>
            <FieldControlText error={error} update="banco" setData={setData} label="Banco emisor*" value={banco} />
        </Col>
        <Col as={FormGroup} md={12}>
            <FieldControlText error={error} update="referencia" setData={setData} label="Referencia de la operación*" value={referencia} />
        </Col>
        <Col as={FormGroup} md={12}>
            <FieldControlNumber error={error} update="monto" setData={setData} label="Monto de la operación*" value={monto} />
        </Col>
        {metodo.dolar ? <></> : <Col as={FormGroup} md={12}><Row>
            <Col className="text-right" xs={7}><b>Monto en dolares:</b></Col>
            <Col className="text-right" xs={5}>{numberFormat(monto / dolar)}</Col>
            <Col className="text-right" xs={7}><b>Tasa de cambio:</b></Col>
            <Col className="text-right" xs={5}>{numberFormat(dolar)}</Col>
        </Row></Col>}
        <Col as={FormGroup} md={12} className="text-right">
            <Button block onClick={save} variant="outline-success">Registrarse</Button>
        </Col>
    </Row>
}
interface State extends partialPago {
    dolar: number
    error?: any
}
interface Props2 {
    model_id: number
    model: "evento" | "grupo"
    precio: number
    metodo: MetodoPago
    update: () => void
    setMetodo: (n: number) => void
}
export default class NuevoPago extends React.Component<Props2, State> {
    procesar = true;
    constructor(p: any) {
        super(p)
        this.state = { banco: '', captura: '', fecha: '', monto: 0, referencia: '', dolar: 1, error: {} }
    }
    render = () => {
        const { state: s, setData, save, props: { metodo, setMetodo, precio } } = this;
        return <>
            <Col md={4}>
                <MetodoMostrar precio={precio} dolar={s.dolar} metodo={metodo} setData={setMetodo} />
            </Col>
            <Col className={s.error.captura ? 'bg-danger' : ''} as='label' md={4}>
                <Row className="pl-5">{s.error.captura ? <b className="text-white">{s.error.captura}</b> : <b>Subir captura</b>}</Row>
                <input style={{ display: "none" }} type="file" accept='image/*'
                    onChange={(e) => setImage(e, captura => setData({ captura }))} />
                <Img fluid src={s.captura !== '' ? s.captura : '/static/uploat.webp'} />
            </Col>
            <Col md={4}>
                <From metodo={metodo} dolar={s.dolar} save={save} data={s} setData={setData} error={s.error} />
            </Col>
        </>
    }
    setData = (p: Partial<State>) => {
        const error: any = this.state.error;
        Object.keys(p).forEach(label => {
            error[label] = undefined;
        });
        this.setState({ error });
        this.setState(p as any);
    };
    setDolar = () => {
        conDolar().valor(dolar => {
            this.setState({ dolar })
        });
    }
    success = () => {
        this.procesar = true;
        this.props.update();
        this.props.setMetodo(0);
        toast('Registrado', { type: toast.TYPE.SUCCESS });
    }
    save = () => {
        if (this.validar()) {
            if (this.procesar) {
                this.procesar = false;
                conPago().nuevo(this.getData(), this.success, this.setError)
            }
        } else this.forceUpdate();
    };
    validar = () => {
        const error: any = {};
        let procesar = true;
        const { captura, fecha, monto, banco, referencia } = this.state;
        if (captura === '') {
            procesar = false;
            error.captura = 'Debe subir la captura de la operacion';
        }
        if (fecha === '') {
            procesar = false;
            error.fecha = 'Debe ingresar la fecha de la operacion';
        }
        if (monto === 0) {
            procesar = false;
            error.monto = 'Debe ingresar el monto de la operacion';
        }
        if (referencia === '') {
            procesar = false;
            error.referencia = 'Debe ingresar la referencia de la operacion';
        }
        if (banco === '') {
            procesar = false;
            error.banco = 'Debe ingresar la referencia de la operacion';
        }
        this.setState({ error });
        return procesar;
    }
    getData() {
        const { captura, fecha, monto, referencia, banco, dolar } = this.state;
        const { metodo: { id }, precio, model_id, model } = this.props;
        return { captura, fecha, monto, referencia, banco, precio, dolar, model_id, model, metodo: id };
    }
    setError = (err: any[]) => {
        this.procesar = true;
        const error: any = {};
        err.forEach((e: any) => {
            if (e.sql) {
                e.sql.forEach((er: string) => toast(er, { type: toast.TYPE.ERROR }));
            } else {
                Object.keys(e).forEach(t => error[t] = e[t])
            }
        })
        this.setData({ error });
        this.forceUpdate();
    }
    componentDidMount(): void {
        this.setDolar();
    }
}
const setImage = (e: React.ChangeEvent<HTMLInputElement>, setData: (s: string) => void) => {
    const files = e.currentTarget.files;
    if (files !== null) ToString(files[0], setData);
}
const ToString = (file: any, setData: (d: string, file: any) => void) => {
    const reader = new FileReader()
    reader.onloadend = async () => setData(await initCanvas(reader.result as string), file);
    reader.readAsDataURL(file);
}
const initCanvas = async (src: string) => {
    const img = await createImage(src);
    if (img === null) return '';
    const canvas = document.createElement('canvas') as HTMLCanvasElement;;
    const { width, height } = img;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (!ctx) return '';
    ctx.drawImage(img, 0, 0, width, height);
    const data = ctx.getImageData(0, 0, width, height);
    ctx.putImageData(data, 0, 0);
    return canvas.toDataURL('image/jpeg', 1);
}
const createImage = (url: string) => new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image as HTMLImageElement))
    image.addEventListener('error', () => reject(null))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
})