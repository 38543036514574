import { Row, Container, Col } from 'react-bootstrap';
import { Html } from '../../Components/Html';
import { getBorderCard } from '@/Utils/getBorder';
export default function CardText({ data: { itens, props } }: any) {
    const { color } = props
    return <Row className='m-0' style={{ background: color, padding: '0 0 2rem 0' }}>
        <Container >
            <Row>{itens.map((i: any) => <Itens key={'itens' + i.id} iten={i} props={props} />)}</Row>
        </Container>
    </Row >
}
function Itens({ iten: { html, props: { color, back, border } }, props: { card, border1 } }: any) {
    const style = getBorderCard(card, border1, border, back);
    return <Col style={{ padding: '.5rem' }} xs={6} lg={4}>
        <div style={{ height: '100%', ...style }} className="c-content-person-1 c-option-2 mt-2">
            <Html className='m-1 p-1 pb-4' style={{ color }} html={html} />
        </div>
    </Col >
}